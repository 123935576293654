export const ANONYMOUS_ID = 'anonId';
export const AUTH_ACTION = 'authAction';
export const AUTH_FAILED = 'authFailed';
export const SIGNUP = 'signUp';
export const LOGIN = 'logIn';
export const CODE_VERIFIER = 'codeVerifier';
export const END_DATE = 'endDate';
export const FIRST_OVERNIGHT = 'firstOvernight';
export const OAUTH_STATE = 'oauthState';
export const START_DATE = 'startDate';
export const TEMPLATE_ID = 'templateId';
export const TIMESPLIT = 'timesplit';
export const TOKEN = 'token';
export const ACCOUNT_DELETED = 'accountDeleted';
export const NOTIFICATION = 'notification';
export const TEMPLATE_REQUIRED = 'templateRequired';
